var languageConfigs = {
  user: {
    profile: "Thông tin cá nhân",
    setting: "Cài đặt",
    logout: "Thoát",
    phone: "Số điện thoại",
    fullName: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    email: "Email",
    address: "Địa chỉ",
    occupation: "Nghể nghiệp",
    country: "Quốc gia",
    call: "Gọi",
    password: "Mật khẩu",
    profileUrl: "Đường liên kết URL",
  },
  booking: {
    time: "Giờ khám",
    price: "Giá dịch vụ",
    complaint: "Lý do đến khám",
    note: "Ghi chú lâm sàng",
    myappointment: "Lịch hẹn của tôi",
  },
  episode: {
    myepisode: "Lượt khám của tôi",
    exam: "Dữ liệu khám",
    info: "Thông tin lượt khám",
    checkintime: "Giờ tiếp nhận",
    clinicaldatanotavailable: "Dữ liệu khám chưa được cập nhật",
    success: "Lượt khám hoàn thành",
    canceled: "Lượt khám đã bị hủy",
  },
  clinic: {
    description: "Mô tả",
    tax: "Mã số thuế",
    name: "Tên phòng khám",
    hotline: "Số hotline",
  },
  service: {
    name: "Tên gói dịch vụ",
    price: "Giá dịch vụ",
    description: "Nội dung khám",
    noproviders: "Chưa có bác sĩ",
  },
  home: {
    welcome: "Chào mừng",
    haveaniceday: "Chúc bạn một ngày tốt lành!",
    report: "Thông số",
    event: "Lịch hẹn",
    episode: "Lượt khám",
    notification: "Thông báo",
  },
  auth: {
    or: "Hoặc",
    emailorphone: "Email / SĐT",
    login: "Đăng nhập",
    signup: "Đăng ký",
    verifyphone: "Vui lòng nhập số điện thoại",
    sendcode: "Gửi mã",
    alreadyhaveaccount: "Bạn đã có tài khoản?",
    donthaveaccount: "Bạn chưa có tài khoản?",
  },
  register: {
    editlogo: "Chọn logo",
    verifyCode: "Nhập mã xác thực",
    becomedoctor: "Đăng ký làm bác sĩ",
    registerclinic: "Đăng ký phòng khám",
    registeraccount: "Đăng ký tài khoản",
    register: "Đăng ký",
    continue: "Tiếp tục",
    additionalinfo: "Ngày sinh & Giới tính",
    male: "Nam",
    female: "Nữ",
    completed: "Hoàn thành",
    startusingapp: "Bắt đầu sử dụng",
    message1:
      "Thông tin này được sử dụng nhằm cung cấp trải nghiệm tốt hơn cho người dùng.",
    message2: "Cảm ơn bạn đã đăng ký tài khoản Sandrasoft!",
    message3:
      "Chúng tôi sẽ liên hệ bạn trong vòng 24h để kiểm chứng thông tin. Bạn đã có thể sử dụng Sandrasoft từ lúc này. Xin cảm ơn.",
  },
  common: {
    back: "Quay lại",
    search: "Tìm kiếm",
    checkin: "Tiếp nhận",
    appoint: "Đặt hẹn",
    choose: "Chọn",
    selecttoview: "Chọn thẻ để xem",
    selectdatetime: "Chọn ngày & giờ",
    create: "Tạo mới",
    info: "Thông tin",
    detail: "Thông tin chi tiết",
    select: "Chọn",
    datetime: "Ngày & giờ",
    confirm: "Chọn",
    confirmed: "Xác nhận",
    delete: "Hủy",
    cancel: "Hủy",
    save: "Lưu",
    finish: "Hoàn thành",
    selectfile: "Chọn tệp",
    today: "Hôm nay",
    at: "Lúc",
    invite: "Thêm",
    user: "Người dùng",
    notprovide: "Chưa có dữ liệu",
    nodata: "Chưa có dữ liệu",
    awesomenodata: "Bạn chưa có thông báo mới.",
    lastvisits: "Lượt khám gần đây",
    verifyUpdate: "Đang khởi chạy ứng dụng, xin vui lòng đợi trong giây lát...",
    serverunavailable: "Server đang được cập nhật. Vui lòng quay lại sau.",
    backtohome: "Về trang chủ",
    timeStart: "Giờ bắt đầu",
    timeEnd: "Giờ kết thúc",
    examcontent: "Nội dung khám",
    clinicaldata: "Ghi chú của bác sĩ",
    account: "Tài khoản",
  },
  nav: {
    home: "Trang chủ",
    patient: "Bệnh nhân",
    doctor: "Bác sĩ",
    booking: "Lịch hẹn",
    episode: "Lượt khám",
    search: "Tìm kiếm",
    setting: "Cài đặt",
    clinic: "Phòng khám",
    service: "Dịch vụ",
  },
  cardTitle: {
    clinic: "Phòng khám của tôi",
    clinics: "Các phòng khám",
    patients: "Danh sách bệnh nhân",
    bookings: "Danh sách lịch hẹn",
    episodes: "Danh sách lượt khám",
    doctors: "Các bác sĩ",
    services: "Các gói dịch vụ",
  },
};

var hostname = window && window.location && window.location.host;
if (hostname) {
  if (hostname.includes("edu.sandrasoft")) {
    languageConfigs = {
      ...languageConfigs,
      booking: {
        time: "Giờ hẹn",
        price: "Giá tiền",
        complaint: "Lý do cuộc hẹn",
        note: "Ghi chú cuộc hẹn",
        myappointment: "Lịch hẹn của tôi",
      },
      episode: {
        myepisode: "Buổi học của tôi",
        exam: "Dữ liệu bài học",
        info: "Thông tin bài học",
        checkintime: "Giờ bắt đầu",
        clinicaldatanotavailable: "Dữ liệu chưa được cập nhật",
        success: "Lượt học hoàn thành",
        canceled: "Lượt học đã hủy",
      },
      clinic: {
        description: "Mô tả",
        tax: "Mã số thuế",
        name: "Tên trung tâm",
        hotline: "Số hotline",
      },
      service: {
        name: "Tên chương trình giảng dạy",
        price: "Giá chương trình",
        description: "Nội dung bài học",
        noproviders: "Chưa có giảng viên",
      },
      home: {
        welcome: "Chào mừng",
        haveaniceday: "Chúc bạn một ngày tốt lành!",
        report: "Thông số",
        event: "Lịch hẹn",
        episode: "Buổi học",
        notification: "Thông báo",
      },
      register: {
        ...languageConfigs.register,
        becomedoctor: "Đăng ký làm giảng viên",
        registerclinic: "Đăng ký trung tâm",
      },
      nav: {
        home: "Trang chủ",
        patient: "Học viên",
        doctor: "Giảng viên",
        booking: "Lịch hẹn",
        episode: "Buổi học",
        search: "Tìm kiếm",
        setting: "Cài đặt",
        clinic: "Trung tâm",
        service: "Chương trình",
      },
      cardTitle: {
        clinic: "Trung tâm của tôi",
        clinics: "Các trung tâm",
        patients: "Danh sách học viên",
        bookings: "Danh sách lịch hẹn",
        episodes: "Danh sách buổi học",
        doctors: "Các giảng viên",
        services: "Các chương trình học",
      },
    };
  }
  if (hostname.includes("cms.sandrasoft")) {
    languageConfigs = {
      ...languageConfigs,
      booking: {
        time: "Giờ hẹn",
        price: "Giá tiền",
        complaint: "Lý do cuộc hẹn",
        note: "Ghi chú cuộc hẹn",
        myappointment: "Lịch hẹn của tôi",
      },
      episode: {
        myepisode: "Hợp đồng của tôi",
        exam: "Dữ liệu hợp đồng",
        info: "Thông tin hợp đồng",
        checkintime: "Giờ bắt đầu",
        clinicaldatanotavailable: "Dữ liệu chưa được cập nhật",
        success: "Hợp đồng hoàn thành",
        canceled: "Hợp đồng đã hủy",
      },
      clinic: {
        description: "Mô tả",
        tax: "Mã số thuế",
        name: "Tên thương hiệu",
        hotline: "Số hotline",
      },
      service: {
        name: "Tên gói dịch vụ/sản phẩm",
        price: "Giá dịch vụ/sản phẩm",
        description: "Nội dung dịch vụ/sản phẩm",
        noproviders: "Chưa có người thực hiện",
      },
      home: {
        welcome: "Chào mừng",
        haveaniceday: "Chúc bạn một ngày tốt lành!",
        report: "Thông số",
        event: "Lịch hẹn",
        episode: "Hợp đồng",
        notification: "Thông báo",
      },
      register: {
        ...languageConfigs.register,
        becomedoctor: "Đăng ký làm cộng tác viên",
        registerclinic: "Đăng ký thương hiệu",
      },
      nav: {
        home: "Trang chủ",
        patient: "Khách hàng",
        doctor: "Cộng tác",
        booking: "Lịch hẹn",
        episode: "Hợp đồng",
        search: "Tìm kiếm",
        setting: "Cài đặt",
        clinic: "Thương hiệu",
        service: "Dịch vụ",
      },
      cardTitle: {
        clinic: "Thương hiệu của tôi",
        clinics: "Các thương hiệu",
        patients: "Danh sách khách hàng",
        bookings: "Danh sách lịch hẹn",
        episodes: "Danh sách hợp đồng",
        doctors: "Các cộng tác viên",
        services: "Các gói dịch vụ",
      },
    };
  }
}
export default languageConfigs;
