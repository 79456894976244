import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./stores";
import Toast from "vue-toastification";
import i18n from "./lang/i18n";
import "./registerServiceWorker";

import EventBus from "@/plugins/eventBus";
Vue.use(EventBus);

import VueMask from "v-mask";
Vue.use(VueMask);

import "vue-toastification/dist/index.css";
const toastOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 1,
  newestOnTop: true,
  hideProgressBar: true,
  position: "top-center",
  timeout: 3000,
};
Vue.use(Toast, toastOptions);

import { FirebaseMessaging } from "@/plugins/firebase";
import { HttpClient } from "./plugins/httpClient";

Vue.mixin({
  computed: {
    isLargeWebVersion() {
      return this.$vuetify.breakpoint.width >= 1280;
    },
    isWebVersion() {
      return this.$vuetify.breakpoint.width >= 820;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.width < 820;
    },
    $httpClient() {
      return new HttpClient(process.env.VUE_APP_API_URL);
    },
    $fcm() {
      return FirebaseMessaging;
    },
  },
  mounted() {
    // if back button is pressed
    window.onpopstate = () => {
      if (this.$store.getters["Mobile/diaglogState"]) {
        this.$router.push(this.$route.name);
        setTimeout(() => {
          this.closeDiaglogIfMobile();
        }, 50);
      }
    };
  },
  methods: {
    callPhone(phone) {
      if (phone) window.open(`tel:${phone}`);
    },
    openDiaglogIfMobile(title) {
      if (this.isMobileVersion) {
        this.$store.commit("Mobile/SET_DIAGLOG_STATE", true);
        this.$store.commit("Mobile/SET_DIAGLOG_TITLE", title);
      }
    },
    closeDiaglogIfMobile() {
      if (this.isMobileVersion) {
        this.$store.commit("Mobile/SET_DIAGLOG_STATE", false);
        this.$store.commit("Mobile/SET_DIAGLOG_TITLE", "");
      }
    },
    showError: function(message) {
      // this.$toast.error(message);
      console.error(message);
    },
    showSuccess: function(message) {
      this.$toast.success(message);
    },
    showInfo: function(message) {
      this.$toast.info(message);
    },
    showWarning: function(message) {
      this.$toast.warning(message);
    },
  },
});

Vue.config.productionTip = false;

export const VueInstance = new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
