var languageConfigs = {
  user: {
    profile: "Peronal Profile",
    setting: "Setting",
    logout: "Logout",
    phone: "Phone",
    fullName: "Full Name",
    dob: "Birthdate",
    gender: "Gneder",
    email: "Email",
    address: "Address",
    occupation: "Occupation",
    country: "Country",
    call: "Call",
    password: "Password",
    profileUrl: "Profile URL",
  },
  booking: {
    time: "Booking Time",
    price: "Price",
    complaint: "Chief Complaint",
    note: "Clinical Note",
    myappointment: "My Appointment",
  },
  episode: {
    myepisode: "My Episode",
    exam: "Medical Exam",
    info: "Episode Information",
    checkintime: "Check-in Time",
    clinicaldatanotavailable: "Clinical data not available",
    success: "Episode Success",
    canceled: "Episode Canceled",
  },
  clinic: {
    description: "Description",
    tax: "Tax Number",
    name: "Clinic Name",
    hotline: "Hotline",
  },
  service: {
    name: "Service Name",
    price: "Price",
    description: "Medical Exam",
    noproviders: "No doctors yet.",
  },
  home: {
    welcome: "Welcome",
    haveaniceday: "Have a nice day at work!",
    report: "Report",
    event: "Event",
    episode: "Episode",
    notification: "Notification",
  },
  auth: {
    or: "Or",
    emailorphone: "Email / Phone",
    login: "Login",
    signup: "Signup",
    verifyphone: "Verify your phone number",
    sendcode: "Send code",
    alreadyhaveaccount: "Already have an account?",
    donthaveaccount: "Dont have an account?",
  },
  register: {
    editlogo: "Edit logo",
    verifyCode: "Verify Code",
    becomedoctor: "Register doctor account",
    registerclinic: "Register Clinic",
    registeraccount: "Register Account",
    register: "Register",
    continue: "Continue",
    additionalinfo: "Additional Info",
    male: "Male",
    female: "Female",
    completed: "Completed",
    startusingapp: "Start using app",
    message1:
      "These information is used to provide best experience and services for our users.",
    message2: "Thank you for signing up at Sandrasoft!",
    message3:
      "We will contact you within 24h to verify. In the meatime you can start using our apllication.",
  },
  common: {
    back: "Back",
    search: "Search",
    checkin: "Check-in",
    appoint: "Appointment",
    choose: "Choose",
    selecttoview: "Select an item to view",
    selectdatetime: "Select date & time",
    create: "Create",
    info: "Information",
    detail: "Detailed information",
    select: "Select",
    datetime: "Date & time",
    confirm: "Confirm",
    confirmed: "Confirmed",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    finish: "Finish",
    selectfile: "Select file",
    today: "Today",
    at: "At",
    invite: "Invite",
    user: "User",
    notprovide: "Not provided",
    nodata: "No data found",
    awesomenodata: "Awesome! No notification yet.",
    lastvisits: "Latest episodes",
    verifyUpdate: "Starting application. Please wait a moment...",
    serverunavailable: "Server is under maintainance. Please come back later.",
    backtohome: "Back to home",
    timeStart: "Time start",
    timeEnd: "Time end",
    examcontent: "Examination content",
    clinicaldata: "Clinical note for doctor",
    account: "Account",
  },
  nav: {
    home: "Home",
    patient: "Patient",
    doctor: "Doctor",
    booking: "Booking",
    episode: "Episode",
    search: "Search",
    setting: "Setting",
    clinic: "Clinic",
    service: "Service",
  },
  cardTitle: {
    clinic: "My Clinic",
    clinics: "Clinics",
    patients: "Patients",
    bookings: "Bookings",
    episodes: "Episodes",
    doctors: "Doctors",
    services: "Services",
  },
};
var hostname = window && window.location && window.location.host;
if (hostname) {
  if (hostname.includes("edu.sandrasoft")) {
    languageConfigs = {
      ...languageConfigs,
      booking: {
        time: "Booking time",
        price: "Price",
        complaint: "Discussion topic",
        note: "Booking note",
        myappointment: "My booking",
      },
      episode: {
        myepisode: "My lesson",
        exam: "Lesson data",
        info: "Lesson info",
        checkintime: "Time start",
        clinicaldatanotavailable: "Data not available yet",
        success: "Lesson completed",
        canceled: "Lesson canceled",
      },
      clinic: {
        description: "Description",
        tax: "Tax number",
        name: "Center name",
        hotline: "Hotline",
      },
      service: {
        name: "Program name",
        price: "Program price",
        description: "Lesson Content",
        noproviders: "No teacher yet",
      },
      home: {
        welcome: "Welcome",
        haveaniceday: "Have a nice day at work!",
        report: "Report",
        event: "Booking",
        episode: "Lesson",
        notification: "Notification",
      },
      register: {
        ...languageConfigs.register,
        becomedoctor: "Register as Teacher",
        registerclinic: "Register your Center",
      },
      nav: {
        home: "Home",
        patient: "Student",
        doctor: "Teacher",
        booking: "Booking",
        episode: "Lesson",
        search: "Search",
        setting: "Setting",
        clinic: "Center",
        service: "Programe",
      },
      cardTitle: {
        clinic: "My center",
        clinics: "Centers",
        patients: "Students",
        bookings: "Bookings",
        episodes: "Lessons",
        doctors: "Teachers",
        services: "Programs",
      },
    };
  }
  if (hostname.includes("cms.sandrasoft")) {
    languageConfigs = {
      ...languageConfigs,
      booking: {
        time: "Booking time",
        price: "Price",
        complaint: "Discussion topic",
        note: "Booking note",
        myappointment: "My booking",
      },
      episode: {
        myepisode: "My commission",
        exam: "Commission data",
        info: "Commission info",
        checkintime: "Time start",
        clinicaldatanotavailable: "Data not available yet",
        success: "Commission completed",
        canceled: "Commission canceled",
      },
      clinic: {
        description: "Description",
        tax: "Tax number",
        name: "Brading name",
        hotline: "Hotline",
      },
      service: {
        name: "Service name",
        price: "Service price",
        description: "Service Content",
        noproviders: "No commissioner yet",
      },
      home: {
        welcome: "Welcome",
        haveaniceday: "Have a nice day at work!",
        report: "Report",
        event: "Booking",
        episode: "Commission",
        notification: "Notification",
      },
      register: {
        ...languageConfigs.register,
        becomedoctor: "Register as Commissioner",
        registerclinic: "Register your Branding",
      },
      nav: {
        home: "Home",
        patient: "Client",
        doctor: "Commissioner",
        booking: "Booking",
        episode: "Commission",
        search: "Search",
        setting: "Setting",
        clinic: "Branding",
        service: "Service",
      },
      cardTitle: {
        clinic: "My branding",
        clinics: "Brandings",
        patients: "Clients",
        bookings: "Bookings",
        episodes: "Commissions",
        doctors: "Commissioners",
        services: "Services",
      },
    };
  }
}
export default languageConfigs;
